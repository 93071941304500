:root{
    --mainblue:#00ABDF;
    --graycolor:#0B2536;
   }
.slider__item {
    max-width: 100%;
    height: 650px;
  }
  
  .slider__item-01 {
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
      url("../Assest/slider-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .slider__item-02 {
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
      url("../Assest/slider-2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .slider__item-03 {
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
      url("../Assest/slider-3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .hero__slider {
    overflow: hidden;
  }
  
  .slider__content {
    padding-top: 100px;
  }
  .text-light {
    width: 750px;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb),var(--bs-text-opacity))!important;
    font-size: 4rem;
    font-weight: 700;
}
  .slider__content h1 {
    color: white;
    font-size: 18px;
  }
  
  .reserve__btn {
    background: var(--mainblue) !important;
    color: #000d6b !important;
    border: none;
    outline: none;
    
    padding: 15px 25px 15px 25px;
  }
  
  .reserve__btn a {
    text-decoration: none;
    font-weight: 600;
    color: #000d6b;
    
    padding: 15px 25px 15px 25px;
}
  .slider__content p {
    color: white;
    font-size: 20px;
    width: 500px;
    margin: 0;

  }
  .reserve__btn:hover {
    background: #000d6b;
    color: #fff;
  }
  
  @media only screen and (max-width: 992px) {
    .slider__item {
      height: 530px;
      max-width: 100vw;
    }
  
    .slider__content {
      margin-top: -50px;
    }
  
    .slider__content h1 {
      font-size: 2rem;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .slider__item {
      height: 450px;
    }
  
    .slider__content h1 {
      font-size: 1.5rem;
    }
  }
:root{
    --mainblue:rgb(0, 171, 223);
    --graycolor:#0B2536;
   }
.plus {
    background: linear-gradient(rgb(0, 171, 223, 0.7), rgb(0, 171, 223, 0.7)),
      url("../Assest/33110.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 65px 35px;
  }
  
  .plus h1 {
    font-weight: 600;
    font-size: 2.8rem;
    color: white;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form__group {
    width: 30%;
    margin-bottom: 0;
  }
  
  .form__group input {
    border: 1px solid #7c8a972a;
    padding: 8px 12px;
  
    width: 100%;
    outline: none;
  }
  .form__group input::placeholder {
    color: #7c8a97;
  }
  

  
  .select__group {
    width: 30%;
  }
  
  .select__group select {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #7c8a972a;
    outline: none;
    color: #7c8a97;
  }
  
  .hero__slider-section {
    position: relative;
    z-index: 10;
    margin-bottom: 200px;
  }
  
  .hero__form {
    position: absolute;
    bottom: -150px;
    z-index: 11;
    width: 100%;
  }
  
  .form__row {
    background: #fff;
    box-shadow: 5px 5px 15px -5px #7c8a97;
  }
  
  .journey__time::before {
    content: "Journey time";
    margin-right: 0.8rem;
    color: #7c8a97;
  }
  
  .find__car-btn {
    background: #000d6b !important;
    color: #fff !important;
    padding: 8px 15px;
    width: 100%;
    border: none;
    outline: none;
  }
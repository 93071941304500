:root{
 --mainblue:#00ABDF;
 --graycolor:#0B2536;
}
.all{
    display: flex;
    background-color: var(--graycolor);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 80px 15px 80px;
    font-family: 'Poppins', sans-serif;

}
.logo {
    width: 12%;
}
.logo img {
    width: 100%;
}
.list{
    display: flex;
}
.list ul {
    display: flex;
    flex-direction: row;
    gap: 30px;
    list-style: none;
    align-items: center;
    padding: 0;
    cursor: pointer;
    margin: 0;
}
.list ul li a{
    color: white;
    text-decoration: none;
    font-size: 18px;
}
.but{
    display: flex;
}
.but button{
    border: 2px solid var(--mainblue);
    background: transparent;
    color: white;
    padding: 10px 50px 10px 50px;
    font-size: 17px;
    cursor: pointer;
}
.but button:hover{
    border:2px solid white;
    padding: 10px 50px 10px 50px;
    font-size: 17px;
}
:root{
    --mainblue:#00ABDF;
    --graycolor:#0B2536;
   }
   .co{
    display: flex;
    background-color: var(--graycolor);
    align-items: center;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    padding: 15px 80px 15px 80px;
    
    justify-content: space-between;
   }
   .logo1{
    width: 58%;
}
.logo1 img {
    width: 100%;
}
   .bar1{
    display: flex;
    flex-direction: column;
    color: white;
    align-items: flex-start;
    gap: 10px;
    width: min-content;
   }
   .bar1 p{
    width: 300px;
    margin: 0px;
    font-size: 16px;
   }
   .social{
    font-size: 35px;
   }
   .bar2{
    display: flex;
   }
   
.bar2 ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    align-items: flex-start;
    padding: 0;
    cursor: pointer;
}
.bar2 ul li a{
    color: white;
    text-decoration: none;
    font-size: 18px;
}
   .bar3{
    display: flex;
    flex-direction: column;
    gap: 10px;
   }
   .bar3 h1{
    color: white;
    margin: 0;
    font-size: 17px;
    font-weight: 600;
   }
   .bar3 input{
    background: transparent;
    border: 2px solid white;
    padding: 10px 20px 10px 20px;
    width: 250px;
   }
   .bar3 button{
    background-color: var(--mainblue);
    font-weight: 600;
    padding: 10px 20px 10px 20px;
    font-size: 16px;
   }
:root{
    --mainblue:#00ABDF;
    --graycolor:#0B2536;
   }
.about__section-item {
    column-gap: 3rem;
  }
  
  .about__section-item p i {
    color: var(--mainblue);
    font-size: 1.1rem;
  }